/**
 * Infographics functionality.
 *
 * @module feature/Infographics
 */
import lodash from 'lodash';
import CountUp from 'countup.js';

/**
 * Adds countup functionality to Infographic when infographic is in viewport.
 * Accepts -/+ as prefix, currency $/€ as prefix, and percentage/K/M as suffix, ie:
 * -1,000
 * +4%
 * $5000000
 * €5000.00
 * 1M
 * 50K
 *
 * @requires lodash
 * @requires countup.js
 * @memberof module:feature/Infographics
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class Infographic {

    /**
     * Initializes DOM elements and set counters and values.
     * @param {jQueryElememt} $element DOM Element to be upgraded.
     */
    constructor($element) {
        this.$element = $($element);
        this.$amount = this.$element.find('.infographic__data');
        this.counter = null;
        this.counterData = {};
    }

    /**
     * Adds positions to DOM elements, initializes counters and defines resize and scroll events.
     */
    init() {

        this.updatePosition();
        this.createCounter().then((counterData) => {
            this.counterData = counterData;
            this.initCounter();
        });

        // update position on resize
        $(window).on('resize.infographic', lodash.debounce(() => {
            this.updatePosition();
            this.initCounter();
        }, 200));

        // check to start counter on scroll
        $(window).on('scroll.infographic', lodash.debounce(() => {
            this.updatePosition();
            this.initCounter();
        }, 200));
    }

    /**
     * Initialize counter if not exists.
     * Starts counting when window scrollpos and offset are higher than elements position in data attribute.
     */
    initCounter() {

        if (!this.counter) {
            this.counter = new CountUp(this.$amount[0], 0, this.counterData.value, this.counterData.decimalLength, this.getRandomDuration(), this.counterData.options);
            if (this.counter.error) {
                console.log(this.counter.error);
            }
        }

        const windowHeight = window.innerHeight;
        const offset = windowHeight / 2;

        // start counter if in viewport
        if (($(window).scrollTop() + offset) >= this.$element.attr('data-position') && !this.$element.hasClass('isCompleted')) {
            this.counter.start(() => {
                this.$element.addClass('isCompleted');
            });
        }
    }

    /**
     * Updates offset position of Infographic, used to determine when
     * infographic is in viewport and when to trigger the counting animation.
     */
    updatePosition() {
        if (!this.$element.hasClass('isCompleted')) {
            this.$element.attr('data-position', this.$element.offset().top);
        }
    }

    /**
     * Creates counter and options object based on data value of element.
     * Sets seperator and decimals, prefix and suffix to options object.
     * @returns {promise} Returns counter object used as input for countUp.
     */
    createCounter() {
        return new Promise((resolve) => {
            let amount = $.trim(this.$amount.text()).replace(/ /g,'');

            // extract prefix
            // ^ asserts position at start of the string
            // match a single character present in the list below [-+$€]

            const prefixRegex = new RegExp(/^[-+$€]/);
            const prefixRes = prefixRegex.exec(amount);
            let prefix = '';
            if (prefixRes) {
                prefix = prefixRes[0];
                amount = amount.substr(prefixRes.index + 1).trim();
            }

            // seperator is always an ','
            const seperator = ',';

            // make sure decimal separator is always a dot
            // we assume that amount contains only one ,

            amount = amount.replace(/,/,'.');

            // * matches the previous token between zero and unlimited times
            // \d matches a digit (equivalent to [0-9])
            // matches a single character in the list .,

            const amountRegex = new RegExp(/[\d.]*/);
            const amountRes = amountRegex.exec(amount);

            // extract suffix like "m²"
            const suffix = amount.substr(amountRes[0].length) ? ` ${amount.substr(amountRes[0].length)}` : '';
            amount = amountRes[0];

            // decimals are always a '.'
            const decimal = '.';

            // get number of decimals
            let decimalIndex = amount.lastIndexOf('.');
            let decimalLength = 0;
            if (decimalIndex > -1) {
                decimalLength = amount.length - (decimalIndex + 1);
            }

            // create options for counter
            const counterData = {
                value: amount,
                decimalLength: decimalLength,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: seperator,
                    decimal: decimal,
                    prefix: prefix,
                    suffix: suffix
                }
            };
            resolve(counterData);
        });
    }

    /**
     * Returns a random duration (in sec) from the duration array.
     * @returns {float} Duration in sec.
     */
    getRandomDuration() {
        const durations = [0.5, 1, 1.5, 2, 2.5, 3, 3.5];
        return durations[Math.floor(Math.random()*durations.length)];
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: Infographic,
    classAsString: 'Infographic',
    cssClass: 'js-infographic'
});

export default Infographic;